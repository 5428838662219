export const tableColumns = [
  {
    title: '人员ID',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '销售姓名',
    dataIndex: 'name',
  },
  {
    title: '联系电话/账号',
    dataIndex: 'phone',
  },
  {
    title: '服务客户',
    // dataIndex: 'users',
    scopedSlots: { customRender: 'users' },
  },
  {
    title: '在职状态',
    scopedSlots: { customRender: 'is_employe' },
  },

  {
    title: '添加时间',
    dataIndex: 'create_time',
    customRender: null,
    sorter: true,
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' },
    width: '156px',
  },
]

export const serviceColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '柜柜账号',
    dataIndex: 'phone',
  },
  {
    title: '注册时间',
    dataIndex: 'create_time',
    customRender: null,
    sorter: true,
  },
]
