import httpRequest from './request'

/**
 * @description 添加销售人员
 * @link https://api.thinkerx.com/web/#/174/12540
 * @params params
 */
export function addSaleMan(
  params: {
    name: string
    phone: string
    is_employe: string
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.post('seeyard/admin/sales_man', params, cb, errCb)
}

/**
 * @description 修改销售人员
 * @link https://api.thinkerx.com/web/#/174/12541
 * @params params
 */
export function editSaleMan(
  params: {
    name: string
    phone: string
    is_employe: string //1在职2离职
    id: number
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.put('seeyard/admin/sales_man', params, cb, errCb)
}

/**
 * @description 删除销售人员
 * @link https://api.thinkerx.com/web/#/174/12542
 * @params params
 */
export function deleteSaleMan(
  params: {
    id: number
  },
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.delete(
    `seeyard/admin/sales_man/${params.id}`,
    params,
    cb,
    errCb
  )
}
/**
 * @description 导出服务客户
 * @link
 * @params params
 */
export function getExportService(params: { salesman_id: number }) {
  return httpRequest.get(
    `seeyard/admin/sales_man/customer/export?salesman_id=${params.salesman_id}`
  )
}
